<template>
    <div class="my-3">
        <div class="text-center mt-2">
            <h1 class="h3">
                Archivos encontrados
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>
        <div class="d-flex flex-wrap">
            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12"
            >
                <div
                    class="my-3 d-flex align-items-center rounded-circle justify-content-around"
                >
                    <vs-input
                        v-model="keyword"
                        type="text"
                        border
                        placeholder="Buscar .."
                        class="mr-2"
                        @click-icon="reloadItems"
                        @keyup.enter="reloadItems"
                    >
                        <template #icon> 🔎 </template>
                    </vs-input>
                    <vs-button relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>

                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />
                    <tooltip-redirect
                        v-if="$ability.hasScope('file:Create')"
                        to="/app/files/new"
                        message="Nuevo archivo"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>
        <div v-show="showTable" class="d-flex flex-wrap mb-5">
            <file-item
                v-for="item in itemsCurrent"
                :key="item.id"
                :file="item"
            />
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import FileItem from "@/components/utils/FileItem.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "FilesIndex",
    components: { TooltipRedirect, FileItem },
    layout: "app",
    meta: {
        roles: ["administrator", "support"]
    },
    data: () => ({
        loaderInstance: null,
        itemsCurrent: [],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        backup: {
            items: [],
            pagination: { pagesNumber: 1, itemsNumber: 1 }
        },
        keyword: ""
    }),
    computed: {
        ...mapState("files", ["files"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        keyword(value) {
            if (value) {
                this.itemsCurrent = this.files.filter((item) =>
                    item.alt.includes(value.toLowerCase())
                );
                this.itemsNumber = this.itemsCurrent.length;
                this.pagesNumber = 1;
                this.currentPage = 1;
            } else {
                this.itemsCurrent = [...this.backup.items];
                this.itemsNumber = Number(this.backup.pagination.itemsNumber);
                this.pagesNumber = Number(this.backup.pagination.pagesNumber);
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("file:List", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("files", ["listFilesPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { items, pagination } = await this.listFilesPaginated({
                    page: this.currentPage,
                    keyword: this.keyword,
                    complete
                });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.backup = {
                    items,
                    pagination: {
                        itemsNumber: Number(this.itemsNumber),
                        pagesNumber: Number(this.pagesNumber)
                    }
                };
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        }
    }
};
</script>
