<template>
    <div class="w-100 d-flex mb-4 border rounded border-info h-28">
        <div
            class="d-flex justify-content-center align-items-center bg-info rounded h-100 col-2 col-lg-1 text-white"
        >
            <strong>
                {{
                    `${file.url.split("").reverse().join("").split(".")[0]}`
                        .split("")
                        .reverse()
                        .join("")
                }}
            </strong>
        </div>
        <div class="w-100 px-2 py-2 position-relative col-10 col-lg-11">
            <router-link
                :to="`/app/files/detail/${file.id}`"
                class="text-primary"
            >
                <p>{{ file.alt || "Indefinido" }}</p>
            </router-link>
            <a :href="file.url" target="_blank" rel="noopener">
                <small> URL </small>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "FileItem",
    props: {
        file: {
            type: Object,
            default: () => ({}),
            required: true
        }
    }
};
</script>
